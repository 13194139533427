import React, {useEffect, useMemo, useState} from 'react'
import {AlternateEmail, FindInPage, Search} from "@mui/icons-material";
import {useUnleashClient} from "@unleash/proxy-client-react";
import getMenuItems, {SubmenuItem} from "@/constants/appMenus";
import {CompanyInfos} from "@/types/CompanyType";
import {useSettingsConstants} from "@/hooks/useSettingsConstants";
import {SettingsCompanyInfos} from "@/constants/settings";
import {useHistory} from "react-router-dom";
import appActions from "./appActions.json"

type AppFeatureType = SubmenuItem & { featureType?: string }

const QuickAccessModal = ({closeModal}: { closeModal: () => void }) => {
    const unleashClient = useUnleashClient();

    const activeFlags = unleashClient.getAllToggles().filter(flag => flag.enabled).map(flag => flag.name);
    const [appFeatures, setAppFeatures] = useState<AppFeatureType[]>([])
    const [companyInfos] = useSettingsConstants<CompanyInfos>(SettingsCompanyInfos);
    const [search, setSearch] = useState("");
    const history = useHistory();

    const goToFeature = (feature: AppFeatureType) => {
        const featureType = feature.featureType ?? "PAGE"
        closeModal()
        history.push({
            pathname: feature.link,
            state: featureType === "ACTION" ? {action: true} : {},
        });
    }

    const filteredFeatures = useMemo(() => {
        if (search !== "") {
            return appFeatures.filter((feature) => {
                return feature.text.toLocaleLowerCase().includes(search.toLowerCase());
            });
        }
        return appFeatures;
    }, [search, appFeatures]);

    useEffect(() => {
        let filteredMenuByFlags = getMenuItems(companyInfos as CompanyInfos).map(menu => {
            return {
                ...menu,
                subMenus: menu.subMenus.filter(submenu => activeFlags.includes(submenu.flag) || submenu.flag === undefined),
            };
        });
        const flattenMenu = filteredMenuByFlags.flatMap(x => x.subMenus)
        setAppFeatures([...flattenMenu, ...appActions])
    }, []);


    return (
        <div className="fixed inset-0 z-50 flex justify-center bg-black/50 pt-40">
            <div className="w-full max-w-xl max-h-[400px] p-6 bg-white rounded-lg shadow-xl">
                <div className="flex items-center gap-3 pb-4 border-b">
                    <Search className="text-gray-400"/>
                    <input
                        onChange={e => setSearch(e.target.value)}
                        type="text"
                        placeholder="Recherchez une fonctionnalité..."
                        autoFocus
                        className="w-full bg-transparent outline-none"
                    />
                </div>

                <div className="mt-4 flex flex-col h-[250px] gap-2 overflow-y-auto">
                    {
                        filteredFeatures.map((menuItem) => {
                            return (
                                <div
                                    onClick={() => goToFeature(menuItem)}
                                    className="h-14 flex items-center justify-between text-gray-600 p-2 py-3 rounded-md  w-full h-full cursor-pointer"
                                >
                                    <div className="flex items-center justify-between gap-2">
                                        {menuItem.featureType === "ACTION" ? <AlternateEmail color="primary"/> :
                                            <FindInPage color="primary"/>}
                                        <span>{menuItem.text}</span>
                                    </div>
                                    <span
                                        className="rounded-full border border-blue-200 px-2 py-0.5 text-[10px]">{menuItem.featureType ?? "PAGE"}</span>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="flex justify-end mt-6 text-sm text-gray-500">
                    <p>Appuyez sur <kbd className="px-2 py-1 bg-gray-100 rounded">échap</kbd> pour quitter</p>
                </div>
            </div>
        </div>
    )
}
export default QuickAccessModal

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CompleteUserContractStepParamType,
  CompleteUserInfoStepParamType,
  Contract,
  Signatory,
  SignatoryForm,
  User,
  UsersState,
} from "@/types/UserType";
import { AxiosResponse, CustomError, ErrorResponse } from "@/types/api/AxiosType";
import { AxiosError } from "axios";
import { Contracts, Users } from "@/requests/users";
import { getFormData, getFormDataWithBlank } from "@/utils/utils";
import { http } from "@/utils/http";
import {
  ADD_FETCH_BANK_URL,
  ADD_FETCH_SIGNATORY_URL,
  CANCEL_END_CONTRACT_URL,
  FETCH_PATCH_DELETE_BANK_URL, USER_NOTIFICATION_URL,
} from "@/constants/endpoints";
import { BankAccount, BankAccountForm } from "@/types/BankType";
import { NotificationItemType } from "pag_collabo/notifications/NotificationItem";

export const getUsers = createAsyncThunk("GET_USERS", async () => {
  return await Users.getUsers();
});

export const addNewUser = createAsyncThunk("CREATE_USER", async (user: Partial<User>) => {
  return await Users.addUser(getFormData(user));
});

export const updateUserData = createAsyncThunk("UPDATE_USER", async (params: any) => {
  return await Users.updateUserData(getFormData(params.data), params.id);
});

export const updateUserInfos = createAsyncThunk("UPDATE_USER_INFO", async (params: any, thunkApi) => {
  try {
    return await Users.updateUserInfos(getFormData(params.data), params.id)
  } catch (err) {
    const error = err as AxiosError<CustomError>;
    const response = error as unknown as AxiosResponse<ErrorResponse>;
    return thunkApi.rejectWithValue({ error: response.data });
  }
});

export const completeUserInfo = createAsyncThunk("UPDATE_USER_STEP", async (param: CompleteUserInfoStepParamType, thunkApi) => {
  try {
    return await Users.updateUser(getFormDataWithBlank(param.user), param.userId, param.step);
  } catch (err: any) {
    const error = err as AxiosError<CustomError>;
    const response = error as unknown as AxiosResponse<ErrorResponse>;
    return thunkApi.rejectWithValue({ error: response.data });
  }

});

export const addContractToUser = createAsyncThunk("ADD_CONTRACT_TO_USER", async (param: CompleteUserContractStepParamType) => {
  return await Contracts.addContractToUser(param.userId, getFormData(param.contract));
});

export const completeUserContract = createAsyncThunk("UPDATE_USER_CONTRACT", async (param: CompleteUserContractStepParamType) => {
  return await Contracts.updateUserContract(param.userId, param.contractId as string, param.step as number, getFormData(param.contract));
});

export const fetchUserAsync = createAsyncThunk<User>(
  "REQ_FETCH_USER",
  async (_, thunkApi) => {
    try {
      return await Users.getUsers();
    } catch (error: any) {
      return thunkApi.rejectWithValue({ error: error.data });
    }
  },
);

export const createUserAsync = createAsyncThunk<AxiosResponse<User>, Partial<User>>(
  "REQ_CREATE_USER",
  async (user: Partial<User>, thunkApi) => {
    try {
      return await Users.addUser(getFormData(user));
    } catch (err: any) {
      const error = err as AxiosError<CustomError>;
      const response = error as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    }
  },
);

export const completeUserContractAsync = createAsyncThunk<AxiosResponse<User>, CompleteUserContractStepParamType>(
  "REQ_UPDATE_USER_CONTRACT",
  async (params: CompleteUserContractStepParamType, thunkApi) => {
    try {
      return await Contracts.updateUserContract(params.userId, params.contractId as string, params.step as number, getFormDataWithBlank(params.contract));
    } catch (err: any) {
      const error = err as AxiosError<CustomError>;
      const response = error as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ error: response.data });
    }
  },
);

export const cancelEndContract = createAsyncThunk<AxiosResponse<Contract>, { userId: string, contractId: string }>(
  "cancel_ended_contract",
  async ({ userId, contractId }, thunkApi) => {
    return http
      .patch<AxiosResponse<Contract>>(CANCEL_END_CONTRACT_URL.replace(":id", userId).replace(":contract_id", contractId))
      .then(res => res.data)
      .catch(e => {
        const error = e as AxiosError<CustomError>;
        const response = error as unknown as AxiosResponse<ErrorResponse>;
        return thunkApi.rejectWithValue({ error: response.data });
      });
  },
);


// fetch all banks
export const fetchBanks = createAsyncThunk("get-banks", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<BankAccount[]>>(ADD_FETCH_BANK_URL)
    .then(res => res.data)
    .catch(e => {
      return thunkApi.rejectWithValue({ data: e.data });
    });
});
// fetch all signatories
export const fetchSignatories = createAsyncThunk("get-signatories", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<Signatory[]>>(ADD_FETCH_SIGNATORY_URL)
    .then(res => res.data)
    .catch(e => {
      return thunkApi.rejectWithValue({ data: e.data });
    });
});

// create bank
export const createBank = createAsyncThunk<AxiosResponse<BankAccount[]>, BankAccountForm>(
  "bank",
  async (banks: Partial<BankAccountForm>, thunkApi) => {
    return http
      .post<AxiosResponse<BankAccount[]>>(ADD_FETCH_BANK_URL, banks)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);

// create signatory
export const createSignatory = createAsyncThunk<AxiosResponse<Signatory[]>, SignatoryForm>(
  "signatory",
  async (signatory: Partial<SignatoryForm>, thunkApi) => {
    return http
      .post<AxiosResponse<Signatory[]>>(ADD_FETCH_SIGNATORY_URL, signatory)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);

export const updateBankAsync = createAsyncThunk<AxiosResponse<BankAccount>, { id: string, data: BankAccountForm }>(
  "UPDATE_BANK",
  async ({ id, data }, thunkApi) => {
    return http
      .patch<AxiosResponse<BankAccount>>(FETCH_PATCH_DELETE_BANK_URL.replace(":id", id), data)
      .then(res => res.data)
      .catch(e => {
        const error = e as AxiosError<CustomError>;
        const response = error.response as unknown as AxiosResponse<ErrorResponse>;
        return thunkApi.rejectWithValue({ error: response.data });
      });
  },
);


export const getUserNotifications = createAsyncThunk("get-notification", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<NotificationItemType[]>>(USER_NOTIFICATION_URL)
    .then(res => res.data)
    .catch(e => {
      return thunkApi.rejectWithValue({ data: e.data });
    });
});


const initialState: UsersState = {
  users: [],
  expiredUsers: [],
  staffMembers: [],
  company: {},
  auth: null,
  usersStatus: "idle",
  userInfos: {},
  error: null,
  banks: [],
  signatories: [],
  usersNotificationStatus: "idle",
  userNotification: [],
};
//TODO review get logic before remove company user
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserKcInfos: (state, action: PayloadAction<{ [key: string]: any }>) => {
      // state.userInfos = JSON.parse(<string>localStorage.getItem("userInfos"));
      state.userInfos = action.payload;
    },
    setUserInRegistration: (state, action: PayloadAction<string | undefined>) => {
      state.userIdInRegistration = action.payload;
    },
    addNewStaffMember: (state, action: PayloadAction<User>) => {
      state.staffMembers = [action.payload, ...state.staffMembers];
    },
    updateUsersList: (state, action: PayloadAction<{ user: User, target: string }>) => {
      const { user, target } = action.payload;
      switch (target) {
        case "users":
          state.users = [{ ...state.users.find(u => u.id === user.id), ...user }, ...state.users.filter(u => u.id !== user.id)];
          break;
        case "staff":
          state.staffMembers = [{ ...state.staffMembers.find(u => u.id === user.id), ...user }, ...state.staffMembers.filter(u => u.id !== user.id)];
          break;
        default:
          break;
      }
    },
    removeUsersList: (state, action: PayloadAction<{ user: User, target: string }>) => {
      const { user, target } = action.payload;
      switch (target) {
        case "users":
          state.users = [{ ...state.users.find(u => u.id === user.id), ...user }, ...state.users.filter(u => u.id !== user.id)];
          break;
        case "staff":
          state.staffMembers = state.staffMembers.filter(u => u.id !== user.id);
          break;
        default:
          break;
      }
    },
    deleteSelectedUser: (state, action: PayloadAction<string>) => {
      state.users = state.users.filter(user => user.id !== action.payload);
    },
    deleteBank: (state, action: PayloadAction<string>) => {
      state.banks = state.banks.filter(bank => bank.id !== action.payload);
    },
    deleteSignatory: (state, action: PayloadAction<string>) => {
      state.signatories = state.signatories.filter(signatory => signatory.id !== action.payload);
    },
    updateNotification: (state, action: PayloadAction<string>) => {
      const notification = state.userNotification.find(notif => notif.id == action.payload);
      notification!.is_read = true;
      state.userNotification = [...state.userNotification.filter(notif => notif.id !== action.payload), notification!];
    },
    deleteNotificationStatus: (state, action: PayloadAction<string>) => {
      state.userNotification = [...state.userNotification.filter(notif => notif.id !== action.payload)];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<{ data: User[] }>) => {
        state.usersStatus = "succeeded";
        state.company = action.payload.data.find(value => value.is_primary);
        state.staffMembers = action.payload.data.filter(user => user.is_member && ["OWNER", "ADMIN", "ACCOUNTANT", "MANAGER"].includes(user.role ? user.role : ""));
        state.auth = action.payload.data.find(value => value.email.toLowerCase() == state.userInfos.email.toLowerCase());
        state.expiredUsers = action.payload.data.filter(user => user.is_collabo && user.is_active && !user.contract);
        state.users = action.payload.data.filter(user => user.is_collabo && (!user.is_active || user.contract));
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error.message;
      });

    builder
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.usersStatus = "succeeded";
        state.users.push(action.payload.data);
      })
      .addCase(completeUserInfo.fulfilled, (state, action) => {
        state.usersStatus = "succeeded";
        const filteredData = state.users.filter(user => {
          return user.id !== action.payload.data.id;
        });
        state.users = [action.payload.data, ...filteredData];
      });
    // .addCase(getUsers.rejected, (state, action) => {
    //   state.usersStatus = "failed";
    //   state.error = action.error.message;
    // });

    builder
      .addCase(updateUserData.pending, (state, _action) => {
        state.usersStatus = "succeeded";
      })
      .addCase(updateUserData.fulfilled, (state, action) => {
        state.usersStatus = "succeeded";
        const filteredData = state.users.filter(user => {
          return user.id !== action.payload.data.id;
        });
        state.users = [action.payload.data, ...filteredData];
      })
      .addCase(updateUserData.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error.message;
      });

    builder
      .addCase(addContractToUser.fulfilled, (state, action) => {
        const filteredData = state.users.filter(user => {
          return user.id !== action.payload.data.id;
        });
        state.users = [...filteredData, action.payload.data];
      })
      .addCase(completeUserContractAsync.fulfilled, (state, action) => {
        const filteredData = state.users.filter(user => {
          return user.id !== action.payload.data.id;
        });
        state.users = [action.payload.data, ...filteredData];
      });

    builder
      .addCase(cancelEndContract.fulfilled, (state, action) => {
        const filteredData = state.users.filter(user => {
          return user.contract.id !== action.payload.data.id;
        });
        const user = state.users.find(user => user.contract.id === action.payload.data.id);
        user.contract = action.payload.data;

        state.users = [...filteredData, user];
      });

    // get  all banks
    builder
      .addCase(fetchBanks.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(fetchBanks.fulfilled, (state, action) => {
        state.usersStatus = "succeeded";
        state.banks = action.payload.data;
      })
      .addCase(fetchBanks.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error;
      });

    // get all signatories
    builder
      .addCase(fetchSignatories.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(fetchSignatories.fulfilled, (state, action) => {
        state.usersStatus = "succeeded";
        state.signatories = action.payload.data;
      })
      .addCase(fetchSignatories.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error;
      });

    // @ts-ignore
    builder
      .addCase(createBank.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(createBank.fulfilled, (state, action: PayloadAction<{ data: BankAccount }>) => {
        state.usersStatus = "succeeded";
        state.banks = [...state.banks, action.payload.data];

      })
      .addCase(createBank.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error;
      });

    // @ts-ignore
    builder
      .addCase(createSignatory.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(createSignatory.fulfilled, (state, action: PayloadAction<{ data: Signatory }>) => {
        state.usersStatus = "succeeded";
        state.signatories = [...state.signatories, action.payload.data];

      })
      .addCase(createSignatory.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error;
      });

    // @ts-ignore
    builder
      .addCase(updateBankAsync.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(updateBankAsync.fulfilled, (state, action: PayloadAction<{ data: BankAccount }>) => {
        state.usersStatus = "succeeded";
        const updatedBank = action.payload.data;
        // Find the index of the bank to be updated
        const index = state.banks.findIndex(bank => bank.id === updatedBank.id);
        state.banks[index] = updatedBank;
      })
      .addCase(updateBankAsync.rejected, (state, action) => {
        state.usersStatus = "failed";
        state.error = action.error;
      });

    builder
      .addCase(getUserNotifications.pending, (state) => {
        state.usersNotificationStatus = "loading";
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.usersNotificationStatus = "succeeded";
        state.userNotification = action.payload.data;
      })
      .addCase(getUserNotifications.rejected, (state, action) => {
        state.usersNotificationStatus = "failed";
        state.error = action.error;
      });
  },
});

export const {
  setUserKcInfos,
  setUserInRegistration,
  addNewStaffMember,
  updateUsersList,
  removeUsersList,
  deleteSelectedUser,
  deleteBank,
  deleteSignatory,
  updateNotification,
  deleteNotificationStatus,
} = usersSlice.actions;
export default usersSlice.reducer;

import { Contract, User } from "@/types/UserType";
import {
  ADD_CONTRACT_TO_USER_URL,
  COMPLETE_USER_PROFILE_URL,
  FETCH_COMPLETE_USER_CONTRACT_URL,
  FETCH_CREATE_USERS_URL,
  FETCH_UPDATE_DELETE_USER_URL,
  FETCH_USER_CONTRACTS_URL,
  UPDATE_USER_DATA_URL,
} from "@/constants/endpoints";
import { AxiosResponse } from "axios";
import { http } from "@/utils/http";

const responseBody = (response: AxiosResponse) => response.data;

const userRequests = {
  get: (url: string) => http.get<User>(url).then(responseBody),
  post: (url: string, user: Partial<User>) => http.post<User>(url, user).then(responseBody),
  delete: (url: string) => http.delete<User>(url).then(responseBody),
  patch: (url: string, user: Partial<User>) => http.patch<User>(url, user).then(responseBody),
};

const contractRequests = {
  get: (url: string) => http.get(url).then(responseBody),
  post: (url: string, contract: Partial<Contract>) => http.post<User>(url, contract).then(responseBody),
  patch: (url: string, contract: Partial<Contract>) => http.patch<User>(url, contract).then(responseBody),
  // delete: (url: string) => http.delete(url).then(responseBody),
};

export const Users = {
  getUsers: () => userRequests.get(FETCH_CREATE_USERS_URL),
  getSingleUser: (id: string): Promise<User> => userRequests.get(`${FETCH_UPDATE_DELETE_USER_URL}`.replace(":id", id)),
  addUser: (user: FormData) => userRequests.post(FETCH_CREATE_USERS_URL, user),
  updateUserInfos: (user: FormData, id: string) => userRequests.patch(`${FETCH_UPDATE_DELETE_USER_URL}`.replace(":id", id), user),
  updateUser: (user: FormData, id: string, step: number): Promise<AxiosResponse<User>> => {
    return userRequests.patch(`${COMPLETE_USER_PROFILE_URL.replace(":id", id)}?step=${step}`, user);
  },
  updateUserData: (data: FormData, id: string): Promise<AxiosResponse<User>> => {
    return userRequests.patch(`${UPDATE_USER_DATA_URL.replace(":id", id)}`, data);
  },

    deleteUser: (id: string): Promise<AxiosResponse<{ [key: string]: any }>> => userRequests.delete(FETCH_UPDATE_DELETE_USER_URL.replace(":id", id)),
};

export const Contracts = {
  /* get all contract for specific user*/
  getUserContracts: (id: string): Promise<Contract[]> => contractRequests.get(FETCH_USER_CONTRACTS_URL.replace(":id", id)),

  /* get single contract for specific user*/
  getSingleUserContract: (id: string, contract_id: string): Promise<Contract> => contractRequests.get(
    FETCH_COMPLETE_USER_CONTRACT_URL.replace(":id", id).replace(":contract_id", contract_id)),

  addContractToUser: (id: string, contract: FormData): Promise<AxiosResponse<User>> => contractRequests.post(ADD_CONTRACT_TO_USER_URL.replace(":id", id), contract),
  updateUserContract: (id: string, contract_id: string, step: number, contract: FormData) => contractRequests.patch(
    `${FETCH_COMPLETE_USER_CONTRACT_URL.replace(":id", id)
      .replace(":contract_id", contract_id)}?step=${step}`, contract),
};

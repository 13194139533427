import React, {useEffect, useState} from "react";
import SearchBar from "comp_common/searchbar/SearchBar";
import CollaboItem from "./collabo-item/CollaboItem";
import classes from "./MenuCollabo.module.scss";
import CollaboListSkeleton from "./collabo-item/CollaboListSkeleton";
import {useAppDispatch, useAppSelector} from "@/hooks/reduxToolkitHook";
import Filter from "@/components/collabo/menu/components/Filter";
import {getFilePath, isUserRegistrationCompleted, searchStringInArray} from "@/utils/utils";
import {User} from "@/types/UserType";
import {Link, useLocation} from "react-router-dom";
import {setUserInRegistration} from "@/redux/features/usersSlice";
import Button from "@mui/material/Button";
import {Close, PersonAddRounded} from "@mui/icons-material";

export default function MenuCollabo() {
    const location = useLocation();
    const currentId = location.pathname.split("/")[2];

    const { users, usersStatus } = useAppSelector((state) => state.users);
    const collabos = users ?? [];
    const [searchString, setSearchString] = useState("");
    const [filteredCollabos, setFilteredCollabos] = useState<User[]>([]);
    const [initialCollabo, setInitialCollabo] = useState<User[]>([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [typeFilter, setTypeFilter] = useState<string | null>(null);
    const [statusFilter, setStatusFilter] = useState<string | null>(null);
    const dispatch = useAppDispatch();

    const handleSearch = (e: any) => {
        let filterKey = e.target.value.trim();
        setSearchString(filterKey);
        setFilteredCollabos(searchStringInArray(collabos, filterKey));
    };

    const filtered = users.filter(collabo => isUserRegistrationCompleted(collabo.enrollment_status));

    const reset = () => {
        setIsFiltered(false);
        setFilteredCollabos(filtered);
    };


    const handleFilter = (item: any) => {
        if (item.field === "type") {
            setTypeFilter(item.value !== "none" ? item.value : null);
        } else if (item.field === "status") {
            setStatusFilter(item.value !== "none" ? item.value : null);
        }
        applyFilters();
        setIsFiltered(true);
    };

    const applyFilters = () => {
        let filtered = [...collabos];

        if (typeFilter) {

            filtered = filtered.filter(collabo => collabo.contract?.user_status == typeFilter);
        }

        if (statusFilter) {
            if (statusFilter === "actif") {
                filtered = filtered.filter(collabo => isUserRegistrationCompleted(collabo.enrollment_status));
            } else if (statusFilter === "pending") {
                filtered = filtered.filter(collabo => !isUserRegistrationCompleted(collabo.enrollment_status));
            } else if (statusFilter === "ended") {
                filtered = filtered.filter(collabo => collabo.contract?.is_ended)
            }
        }

        setFilteredCollabos(filtered);
    };

    useEffect(() => {
        applyFilters();
    }, [typeFilter, statusFilter]);

    useEffect(() => {
        setInitialCollabo(searchStringInArray(users, searchString));
    }, [users]);

    useEffect(() => {
        const filtered = initialCollabo.filter(collabo => !collabo.is_active || collabo.contract);
        setFilteredCollabos(filtered);

    }, [initialCollabo]);

    return (
      <div className="menu-collabo text-center h-full w-full">
          <div className="overflow-y overflow-x-hidden mx-6 mt-12">
              <Button
                className="w-full"
                onClick={() => dispatch(setUserInRegistration(undefined))}
                variant="contained"
                startIcon={<PersonAddRounded />}
              >
                  <Link to="/ajout-collaborateur/identite">Ajouter un collaborateur</Link>
              </Button>
              <div className="flex my-4">
                  <SearchBar
                    className={`${classes.max_372px} ${classes.searchbar}  mx-auto w-full`}
                    onChange={(e) => handleSearch(e)}
                  />
                  <Filter onFilter={(item: any) => handleFilter(item)} isFiltered={isFiltered} />

              </div>

          </div>

          <div className={`t-8 px-6 overflow-y-scroll pb-8 ${classes.collabo_list}`}>
              {
                  isFiltered && (
                    <button style={{ borderRadius: 14, padding: 5, borderWidth: 2, paddingLeft: 10, fontSize: 16 }}
                            className="bg-gray-200 float-left my-3" onClick={reset}>Réinitialiser <Close />
                    </button>
                  )
              }
              {usersStatus === "loading" ?
                <CollaboListSkeleton /> :
                (
                  filteredCollabos.length ?
                    filteredCollabos.map((collabo, i) => (
                      <CollaboItem
                        key={i}
                        className={`${classes.max_372px}`}
                        isSelected={currentId === collabo.id}
                        collabo={collabo}
                      />
                    ))
                    :
                    (
                      !isFiltered && users.length && !searchString.length ?
                        <CollaboListSkeleton />
                        :
                        <div className="mt-24 flex flex-col items-center">
                            <img src={getFilePath("empty_search.svg")} className="w-36"/>
                            <p className="mt-2 text-base font-normal">
                                Aucun collaborateur trouvé
                            </p>
                        </div>
                    )
                )
              }

          </div>
      </div>

    );
}

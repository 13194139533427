import React, {useState} from "react";
import "./MenuCompany.scss";
import {NavLink, useLocation, useRouteMatch} from "react-router-dom";
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion";
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary";
import {AccordionDetails} from "@mui/material";
import {useAppSelector} from "@/hooks/reduxToolkitHook";
import {AbsencesTypesTags} from "@/constants/absencesTypesTags";
import {useUnleashClient} from "@unleash/proxy-client-react";

const Accordion = (props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    children={props.children}
    sx={{
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
    }}
  />);

const AccordionSummary = (props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    sx={{
      flexDirection: "row-reverse",
      "& .MuiAccordionSummary-content": {
        // marginLeft: theme.spacing(1),
        margin: "0px auto",
      },
    }}
  />
);

const MenuCompany = ({ menus }) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(-1);

    const unleashClient = useUnleashClient();

    const activeFlags = unleashClient.getAllToggles().filter(flag => flag.enabled).map(flag => flag.name);

  let filteredMenuByFlags = menus.map(menu => {
    return {
      ...menu,
        subMenus: menu.subMenus.filter(submenu => activeFlags.includes(submenu.flag) || submenu.flag === undefined),
    };
  });
  filteredMenuByFlags = filteredMenuByFlags.filter(menu => {
    return menu.subMenus && menu.subMenus.length || menu.link;
  });

  const {
    requestedAbsences: absencesList,
    absencesTypes: abs_types,
  } = useAppSelector(state => state.absences);
  const { requestedAcomptes: acomptes } = useAppSelector((state) => state.salaryAdvances);
  const { requestedSalaryAdvances: salaryAdvances } = useAppSelector((state) => state.salaryAdvances);

  let typesIds = abs_types.filter(t => t.value !== AbsencesTypesTags.START_WORK).map(t => t.id);

  const absences = absencesList.filter(a => typesIds.includes(a.type!));

  const requestsStats: { [key: string]: number } = {
    "ABSENCES": absences.filter((elmt) => elmt.approbation_status === "PENDING").length,
    "ADVANCES": salaryAdvances.filter((elmt) => elmt.approbation_status === "PENDING").length,
    "ACOMPTES": acomptes.filter((elmt) => elmt.approbation_status === "PENDING").length,
  };

  const totalRequests = requestsStats.ABSENCES + requestsStats.ADVANCES + requestsStats.ACOMPTES;

  const handleCollapse = (panel: number) => {
    setExpanded(val => val == panel ? -1 : panel);
  };

  return (
    <div className="mt-10">
      <NavLink to="/" className="">
        <div
          className={"mx-5 px-3 flex items-center py-[10px] rounded cursor-pointer text-blue-800 " + (useRouteMatch({
            path: ["/", "/dashboard"],
            exact: true,
          }) ? "bg-blue-100 hover:text-blue-800" : "hover:text-blue-500")}>
          <i className="ut-spe-category ut--xl mr-3" />
          <p className="font-medium">Tableau de bord</p>
        </div>
      </NavLink>


      {filteredMenuByFlags.map((menu, i) => {
        if (!menu.isEnabled) {
          return <Accordion key={i}
                            expanded={false}
                            className={"mx-5 my-[10px] rounded cursor-pointer"}
                            onChange={() => handleCollapse(i)}>
            <AccordionSummary
              aria-controls="panel1d-content"
              className={"!mx-3 !p-0 text-blue-800 flex items-center hover:text-blue-500"}
            >
              <ul>
                <NavLink key={i} to={menu.unavailabilityPageLink} className="flex">
                  <li
                    className={"py-[4px] hover:text-blue-500 " + (location.pathname.includes(menu.link) ? "text-blue-500" : "")}>
                    <i className={menu.iconNameWithClass + " ut--xl mr-3"} /> {menu.text}
                  </li>
                </NavLink>
              </ul>
            </AccordionSummary>
          </Accordion>;
        }
        return menu.subMenus.length ?
          <Accordion key={i}
                     expanded={!!menu.subMenus.filter(x => location.pathname.includes(x.link)).length || expanded == i}
                     className={"mx-5 my-[10px] rounded cursor-pointer " + (!!menu.subMenus.filter(x => location.pathname.includes(x.link)).length ? "!bg-blue-100" : "")}
                     onChange={() => handleCollapse(i)}>
            <AccordionSummary
              aria-controls="panel1d-content"
              className={"!mx-3 !p-0 text-blue-800 flex items-center justify-center" + (!!menu.subMenus.filter(x => location.pathname.includes(x.link)).length ? "hover:text-blue-800" : "hover:text-blue-500")}
            >
              <i className={menu.iconNameWithClass + " ut--xl mr-3"} />
              <p
                className="font-medium">{menu.text}</p>
              {
                menu.tag === "DEMANDS" && totalRequests > 0 ?
                  <p><span
                    className={`py-[4px] px-[4px] w-[${String(totalRequests).length}5px] text-center bg-red-500 rounded-full text-white ml-2 absolute top-[5px] text-[10px] font-bold`}> {totalRequests}</span>
                  </p> : ""
              }
            </AccordionSummary>
            <AccordionDetails className="bg-blue-50 pl-6">
              <ul className="ml-5 text-gray-600">
                {menu.subMenus.map((subMenu, i) => (
                  <NavLink key={i} to={subMenu.isEnabled ? subMenu.link : subMenu.unavailabilityPageLink} className="">
                    <li
                        className={"flex justify-between py-[4px] hover:text-blue-500 " + (location.pathname.includes(subMenu.isEnabled ? subMenu.link : subMenu.unavailabilityPageLink) ? "text-blue-500" : "")}>
                        <span>
                            {subMenu.text} {menu.tag === "DEMANDS" && subMenu.tag && requestsStats[subMenu.tag] > 0 ?
                            <span className="px-1.5"> {` (${requestsStats[subMenu.tag]}) `} </span> : ""}
                        </span>
                        {subMenu.isBetaFeature ? <small
                            className="text-green-600 font-semibold rounded bg-green-200 px-1.5 py-0.5">Beta</small> : null}
                    </li>
                  </NavLink>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion> :
          <Accordion key={i}
                     expanded={false}
                     className={"mx-5 my-[10px] rounded cursor-pointer"}
                     onChange={() => handleCollapse(i)}>
            <AccordionSummary
              aria-controls="panel1d-content"
              className={"!mx-3 !p-0 text-blue-800 flex items-center hover:text-blue-500"}
            >
              <ul>
                <NavLink key={i} to={menu.link} className="flex">
                  <li
                      className={"flex justify-between py-[4px] hover:text-blue-500 " + (location.pathname.includes(menu.link) ? "text-blue-500" : "")}>
                    <span>
                      <i className={menu.iconNameWithClass + " ut--xl mr-3"}/> {menu.text}
                    </span>
                      {menu.isBetaFeature ? <small
                          className="text-blue-500 font-semibold rounded bg-blue-100 px-1.5 py-0.5">Beta</small> : null}
                  </li>
                </NavLink>
              </ul>
            </AccordionSummary>
          </Accordion>;
      })}
    </div>
  );
};

export default MenuCompany;

// @ts-ignore
import moment from "moment/min/moment-with-locales";
import {Moment} from "moment";
import {Contract} from "@/types/UserType";
import {store} from "@/store";

/**
 * Helper to get date between date ranges
 * @param startDate
 * @param endDate
 */
export const getDaysFromRange = (startDate: any, endDate: any) => {
  const now = startDate.clone(), dates = [];
  let i = moment(now).toDate().getDate();
  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("ddd")[0].toUpperCase() + " " + i);
    now.add(1, "days");
    i++;
  }
  return dates;
};


/**
 *  Get month boundaries from date
 * @param date
 */
export const getMonthBoundaries = (date: Moment): Moment[] => {
  const currMonth = date.month() + 1;
  const currYear = date.year();
  const firstDate = moment(`01-${("0" + currMonth).slice(-2)}-${currYear}`, "MM-MM-YYYY");
  const endDate = moment(`${date.daysInMonth()}-${("0" + currMonth).slice(-2)}-${currYear}`);

  return [firstDate, endDate];
};

export const checkIfUserContractIsActive = (contract: Contract) => {
  if (!contract.end || !contract.out_date) {
    return moment().isSameOrAfter(contract.start);
  } else {
    const targetMonth = store.getState().targetMonth.targetMonth;
    const targetMonthLastDay = moment(`1-${targetMonth}`, "DD-MM-YYYY").endOf("month");
    if (moment().isBefore(contract.start)) {
      return false;
    } else return !!moment(contract.end || contract.out_date).isSameOrBefore(targetMonthLastDay);
  }
};

export const checkIfContractStart = (contract: Contract) => {
  return moment().isAfter(contract.start);
}